:root {
  --primary: 0, 120, 135; /* #007887 */
  --input: #333;
  --error: 241, 68, 68; /* #f14444 */
  --success: 77, 175, 47; /* #4daf2f */
  --font-family-display: "Fira Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  --toastify-text-color-light: #fff;
  --toastify-icon-color-info: var(--toastify-text-color-light);
  --toastify-icon-color-success: var(--toastify-text-color-light);
  --toastify-icon-color-warning: var(--toastify-text-color-light);
  --toastify-icon-color-error: var(--toastify-text-color-light);
}

* {
  box-sizing: border-box;
}

small {
  display: block;
  /* font-style: italic; */
  font-size: 0.75rem;
  opacity: 0.5;
  margin-bottom: 10px;
}

.logo {
  display: inline-block;
  margin-bottom: 20px;
}

h1,
h2 {
  color: rgb(var(--primary));
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 0 20px;
}

h1,
h2 {
  font-size: 1.2rem;
}

.Toastify__toast {
  font-family: var(--font-family-display);
  line-height: 1.2;
  font-weight: bold;
}

.Toastify__close-button {
  color: var(--toastify-text-color-light);
  opacity: 1;
}

.Toastify__toast--success {
  background: rgba(var(--success), 0.95);
  text-transform: uppercase;
}

.Toastify__toast--error {
  background: rgba(var(--error), 0.95);
  font-size: 0.9rem;
}
